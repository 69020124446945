$(document).ready(function () {
    $("a.nav__item").removeClass("active");
    $("a.nav__item").on("click", function () {
        $(this).addClass("active");
    });

    var href = window.location.href.split("/");
    let splitedHref, lastSplited;

    $(".nav__container a").each(function (e, i) {
        splitedHref = $(this).attr("href").split("/");
        lastSplited = splitedHref[splitedHref.length - 1];
        if (href.includes(lastSplited)) {
            $(this).addClass("active");
        }
    });
});