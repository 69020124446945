export default {
    init() {
        let allUsers, inptValue, thisElement;

        $.ajaxSetup({
            type: "get",
            data: {
                _token: $("input.js-input-csrf-token").val(),
            },
        });

        $.ajax({
            url: $("input.js-input-route-telegrambotuser-getAllUsers").val(),
            success: function (response) {
                allUsers = response;
            },
        });

        $("#userSearchInput").on("keyup", function () {
            $("#tlgbotusers-tbody").empty();
            inptValue = $(this).val();
            if ($(this).val()) {
                $.each(allUsers, function (index, user) {
                    if (
                        user["id"]
                            .toString()
                            .toLowerCase()
                            .includes(inptValue.toString().toLowerCase()) ||
                        (JSON.parse(user["name"]) || "")
                            .toString()
                            .toLowerCase()
                            .includes(inptValue.toString().toLowerCase()) ||
                        (user["username"] || "")
                            .toString()
                            .toLowerCase()
                            .includes(inptValue.toString().toLowerCase())
                    ) {
                        $("#tlgbotusers-tbody").append(`
                                <tr>
                                    <td>${index + 1}</td>
                                    <td>${user["id"]}</td>
                                    <td>${JSON.parse(user["name"])}</td>
                                    <td>${user["username"]}</td>
                                    <td>${user["accounts"]}</td>
                                    <td>${user["balance"]}</td>
                                    <td><a href="${
                                        $(
                                            "input.js-input-route-telegrambotuser-edit"
                                        ).val() +
                                        "/" +
                                        user["id"]
                                    }"><i class="fas fa-edit"></i></a>
                                    </td>
                                </tr>
                        `);
                    }
                });
            } else {
                $.each(allUsers, function (index, user) {
                    if (index <= 49) {
                        $("#tlgbotusers-tbody").append(`
                                <tr>
                                    <td>${index + 1}</td>
                                    <td>${user["id"]}</td>
                                    <td>${JSON.parse(user["name"])}</td>
                                    <td>${user["username"]}</td>
                                    <td>${user["accounts"]}</td>
                                    <td>${user["balance"]}</td>
                                    <td><a href="${
                                        $(
                                            "input.js-input-route-telegrambotuser-edit"
                                        ).val() +
                                        "/" +
                                        user["id"]
                                    }"><i class="fas fa-edit"></i></a>
                                    </td>
                                </tr>
                            `);
                    }
                });
            }
        });

        $("#sendallmessagebtn").on("click", function () {
            $.ajax({
                url: $(
                    "input.js-input-route-telegrambotuser-sendMessageToAll"
                ).val(),
                data: {
                    message: $("#message-to-all-textarea").val(),
                },
            });

            $("#message-to-all-textarea").val("");

            Toast.fire(
                "عملیات موفق",
                "پیام با موفقیت به همه کاربران ارسال شد",
                "success"
            );
        });

        $('span.change-active-status').on('click',function () {
            thisElement = $(this)
            $.ajax({
                url: $(
                    "input.js-input-route-telegrambotuser-changeActiveStatus"
                ).val(),
                data: {
                    user_id: thisElement.data('userId'),
                },
                success : function (response) {
                    if (response == 0) {
                        thisElement.html('غیرفعال')
                        thisElement.removeClass('bg-success')
                        thisElement.addClass('bg-danger')
                        Toast.fire(
                            "عملیات موفق",
                            "وضعیت کاربر با موفقیت بلاک شد",
                            "success"
                        );
                    } else if (response == 1) {
                        thisElement.html('فعال')
                        thisElement.removeClass('bg-danger')
                        thisElement.addClass('bg-success')
                        Toast.fire(
                            "عملیات موفق",
                            "وضعیت کاربر با موفقیت آنبلاک شد",
                            "success"
                        );
                    }
   
                }
            });
        })

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
